@import "../../Constantes";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  overflow: auto;
  color:#FFF;
}

.modal.transitioning {
  opacity: 0;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.modal-window{
  color:#FFF;
  padding: 0 30px 30px;
  margin: 5rem auto;
  border: 1px solid #ffffff22;
  background: #2b2b2b linear-gradient(0deg, rgba(33, 33, 33, 0.1) 0%, rgba(11, 11, 11, 0.1) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  max-height: 90%;
  max-width: 90%;
  overflow-y: scroll;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar{
  display: none;
}
.modal-heading{
   position: sticky;
   top: 0;
   width:100%;
   height: 30px;
}
.modal-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.modal-close{
  position: absolute;
  top:15px;
  right:15px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: stretch;
}

.modal-body form{
    display: flex;
  flex-direction: column;
  align-items: center;
}