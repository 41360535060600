@import "../../Constantes";

.iconButton {
  text-align: center;
  background-color: $main-color-back;
  color:$main-color-dark;
  padding: 5px 7px;
  border-radius: 5px;
  border-style: none;
  font-size: 15px;
  cursor:pointer;
}

.iconButton:hover{
  filter: brightness(85%);
}