@import "../../../../Constantes";

.content-admin-users {
  padding: 24px;
  min-height: 360px;
}

.content-heading-admin-users{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}