.explorer-files-content {
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.explorer-files-content ul {
  list-style-type: none;
  padding-left: 25px;
  margin: 0;
}

.explorer-files-content li {
  margin: 10px 0 0;
  padding: 0;
  position: relative;
}

.explorer-files-content span {
  display: flex;
  margin-left: 4px;
  cursor: pointer;
}

.explorer-files-content ul ul {
  display: none;
}

.explorer-files-content .divTitle {
  margin-left: 25px;
}

.explorer-files-content li:active ul {
  display: block;
}
