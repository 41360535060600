@import "../../../Constantes.scss";

//header -------------------------
.headerDashboard{
  height: $height-header;
  background-color: $main-color-dark-light;
}

.headerLogo {
  display: block;
  float: left;
  margin-top: calc($height-header/2 - 22px);
  width: 40px;
  height: 40px;
  background-color: $main-color-back;
  margin-left: 10px;
}

.headerLogo>img{
  float: left;
}

.headerTitle {
  float: left;
  width: 115px;
  height:20px;
  margin-top: calc($height-header/2 - 10px);
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  width: 120px;
  text-align: center;
}

.menuItem.active{
 background-color: #333;
}

.spacerHeader{
  font-weight: 100;
  background: $main-color-back;
}

.divCompte{
  margin-left: auto;
  margin-right: 40px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
}

.logoCompte {
  width: 45px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid $SUN_FLOWER;
}

// DROPDOWN MENU



/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdownContentLogo {
  right: -1px;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  display: none;
  position: absolute;
  left:-20px;
  right: 0px;
  background-color: $main-color-dark-light2;
  width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1000;
  font-size: 12px;
  font-weight: bold;
  color:$WHITE;
}

/* Links inside the dropdown */
 .dropdownItem{
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor:pointer;
}
.dropdownItemSeparator{
  height: 1px;
  width: 90px;
  border-bottom: 1px dashed #FFF;
  margin-left: auto;
  margin-right: auto;
}

/* Change color of dropdown links on hover */
.dropdownContent .dropdownItem:hover {background-color: $LIGHT_GREY;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {display: block;}
//.dropdown:active .dropdownContent {display: none;}

.menuToggle {
    display: none;
    position: absolute;
    top: 22px;
    right: 15px;
    cursor: pointer;
    z-index: 9999;
}

.menuToggle .iconBar {
    display: block;
    width: 22px;
    height: 3px;
    background-color: $SUN_FLOWER;
    margin-bottom: 5px;
}

/* Styles pour les écrans de petite taille */
@media screen and (max-width: $SIZE_SMALL_SCREEN) {
  .menuToggle {
    display: block; /* Afficher le bouton du menu hamburger */
  }
  .menuBar{
    display:none;
  }
  /* Styles pour afficher le menu lorsqu'il est ouvert */
  .menuBar.show {
    position:absolute;
    top:$height-header;
    right: 0;
    left: 0;
    display: flex;
    height: calc(100vh - $height-header);
    flex-direction: column;
    background-color:  $main-color-dark-light;
    z-index: 1000;
  }

  .spacerHeader{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:120px;
    height:4px;
  }

  .menuItem{
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

}

/* Styles pour les écrans de taille moyenne et grande */
@media screen and (min-width: $SIZE_SMALL_SCREEN) {
  .spacerHeader{
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -1px;
    margin-right: -1px;
    height:16px;
    width:3px;
    z-index: 1000;
  }
  .menuToggle {
    display: none; /* Masquer le bouton du menu hamburger */
  }

   .menuBar {
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 20px;
    width: calc(100% - 205px);
    height: $height-header;
  }
}

// end dropdown !!
//end header ----------------

