@import "../../../Constantes";

.FCInputLabel {
  display: inline-block;
  font-size: 13px;
  color: #FFF;

   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

}

.FCInputResultsItem:first-of-type{
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #FFF;
}

.FCInputResultsItem{
  min-height: 45px;
  background-color: transparent;//$WHITE;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #FFF;
  overflow: hidden;
  vertical-align: middle;
}

.FCInputResultsItem:hover{
  background-color: #333;
}
.selected{
  background-color: #333;
}

.FCInputResultsItem p {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-left: 10px;
  text-transform: uppercase;
  vertical-align: middle;
  color: #FFF;
}