@import "../../../Constantes";

.FCCheckboxLabel {
  width: 110px;
  display: inline-block;
  font-size: 13px;
  color: #FFF;

  // Pour compatibilité avec form CV
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  margin-left:2px
}

.FCCheckbox {
  padding-left:10px;
  border-radius: 5px;
  border: 1px groove $main-color-dark;
  color: $main-color-dark;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 13px;
}