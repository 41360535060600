.createaccountmailingview {

  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;

  margin-top: 10px;

  .form-createaccountmailingview {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .title-createaccountmailingview {
    margin-bottom: 25px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
}