@import "../../Constantes.scss";

.login {
  background: linear-gradient(0deg, #f1b50f 0%, $main-color-back 80%);
  min-height: 95vh;
  color: $main-color-dark;
}

.div_logo {
  padding-top: 50px;
  display:flex;
  flex-direction: column;
  text-align: center;
}

.div_authentification {
  width: 400px;
  display:flex;
  flex-direction: column;
  text-align: center;
  color: white;
  padding-bottom: 50px;
  padding-top: 10px;
  margin-bottom: 120px;
  border: 1px solid #ffffff22;
  background: linear-gradient(0deg, rgba(33, 33, 33, 0.9)  0%, rgba(11, 11, 11, 0.9) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.labelInput {
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  margin-left:2px
}

.inputLogin {
  border-radius: 5px;
  border-color: $main-color-dark;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  border-style:none;
}

.labelMDPOublieEtCreationCompte {
  color: white;
  font-size: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;

  &:hover{
    text-decoration: underline;
  }
}

.footerLogin {
  min-height: 5vh;
  background: linear-gradient(0deg, rgba(33, 33, 33, 0.9) 0%, rgba(11, 11, 11, 0.9) 100%);
  box-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.5333333333);

  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;

  .titleFooterLogin {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
    justify-content: center;

    .hrefFooterLogin {
      color: $WHITE;
    }
  }
}

