@import "../../../Constantes";

.divContentCalendar {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  max-width: 100%;
  min-height: 100%;
}

.divCalendarMonth {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 10px;

  .divNameMonthAndDays {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: $main-color-dark-light;

    .title{
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      text-transform: uppercase;
      color:$WHITE;
      font-weight: bold;
    }

    .divMonthAndAllDay {
      display: flex;
      flex-direction: column;
      margin:5px;

      @media (min-width: $SIZE_SMALL_SCREEN) {
        .day {
          height: 25px;
          width: 25px;
          font-size: 11px;
          margin:5px;
        }
      }

      @media (max-width: $SIZE_SMALL_SCREEN) {
        .day {
          height: 20px;
          width: 20px;
          font-size: 10px;
          margin:3px;
        }
      }
      .day {

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;

        border-radius: 25px;

        font-weight: 600;
        color: $main-color-grey;
      }
    }
  }
}

