@import "../../../../Constantes";

.content-page-home {
  padding: 24px;
  min-height: 360px;
}

.content-heading-home{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}