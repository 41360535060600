@import "../../../Constantes";

.content-page-billing {
  padding: 24px;
  min-height: 360px;
}

.content-heading{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.div-title-billing {
  display: flex;
}