@import "../../../../Constantes";

.resume-tva {
  margin-top: 10px;
  color: white;
  font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-evenly;
}
.line-resume-tva {
  display: flex;
  margin-bottom: 5px;
}

.col-line-resume-tva{
  width: 150px;
}

.col-line-value-resume-tva {
  font-weight: 600
}

@media (min-width: $SIZE_SMALL_SCREEN) {
  .resume-tva{
    flex-direction: row;
  }
}

@media (max-width: $SIZE_SMALL_SCREEN){
  .resume-tva{
    flex-direction: column;
  }
}