@import "../../../../Constantes";

.onglet-container {
  display: flex;
  justify-content: center;
}

.onglet {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid $main-color-grey;
  background-color: $main-color-grey;
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
}

.onglet.active {
  background-color: $main-color-back;
  border-color: $main-color-back;
  color:$main-color-dark;
}

.onglet:hover:not(.active) {
  background-color: $main-color-back;
  border-color: $main-color-back;
  color:$main-color-dark;
  filter: brightness(85%);
}

.contenu-onglets {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  margin: auto auto 10px auto;
  width: 80%;
}

.content-page-account {
  margin-top: 25px;
  min-height: 360px;
  width:100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}