@import "../../../Constantes";

.FCInputLabel {
  display: inline-block;
  font-size: 13px;
  color: #FFF;

  // Pour compatibilité avec form CV
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  margin-left:2px
}

.FCInput {
  padding-left:10px;
  border-radius: 5px;
  border: 1px groove $main-background-Color;
  color: $main-color-dark;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 25px;
  font-size: 13px;
}

input[type="file"] {
  height: 50px;
  color: $main-background-Color;
}

input[type="file"]::file-selector-button {
  background-color: $main-color-back;
  color:$main-color-dark;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border-style: none;
  font-weight: 700;
  font-size: 11px;
  cursor:pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

input[type="file"]::file-selector-button:hover {
  filter: brightness(85%);
}

.horizontalFormInput{
  display: flex;
  flex-direction: row;
}

.verticalFormInput{
  display: flex;
  flex-direction: column;
}