@import "../../Constantes";

.fab-container {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column-reverse;
  position: fixed;
  max-height: 25px;

  &.open {
    max-height: max-content;
  }

  .divFloatButton {
    border-radius: 100%;
    box-shadow: rgba(50, 50, 50, 0.3) 0 3px 5px;
    display: grid;
    place-items: center;
    margin: 8px 0;
    font-size: 15px;
    padding: 12px;
    cursor: pointer;
    position: relative;
    background-color:  #FFFFFF;
    color: $main-color-dark-light2;
  }

  .fab-button {
    background-color: #FFFFFF;

    svg {
      fill: white;
    }
  }

  .fab-action {
    transform: translateY(50px) scale(0);
    transition: transform 300ms, opacity 300ms;
    opacity: 0;

    &:hover {
      .tooltip {
        transform: translateX(-100%) scale(1);
        opacity: 1;
      }
    }

    &.open {
      transform: translateY(0) scale(1);
      opacity: 1;
    }

    .tooltip {
      padding: 4px 10px;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      left: -12px;
      transform: translateX(-75%);
      background-color: $main-color-dark-light2;
      border-radius: 4px;
      color: white;
      opacity: 0;
      transition: transform 300ms, opacity 300ms;
      user-select: none;
    }
  }
}