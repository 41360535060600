@import "../../Constantes";

.btn {
  margin-top: 25px;
  text-align: center;
  background-color: $main-color-back;
  color:$main-color-dark;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border-style: none;
  font-weight: 700;
  font-size: 11px;
  cursor:pointer;
}

.btn:hover{
 filter: brightness(85%);
}