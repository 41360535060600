@import "../../../Constantes";

.content-page-business-deal {
  padding: 24px;
  min-height: 360px;
}

.content-heading{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}