@import "../../Constantes.scss";

.cardContainer{
    background-color: #444;
    height: 100vh;
    margin: 0;
}
.divCard {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 1024px) {
    .divCard {
        flex-direction: row;
    }
}

@media only screen and (max-width: 1023px) {
    .divCard {
        flex-direction: column;
    }
}

