@import "../../../../Constantes";

.content-page-account {
  margin-top: 25px;
  min-height: 360px;
  width:100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
