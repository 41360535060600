//@import "./resetCSS.scss";
@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0";
@import "./Constantes.scss";

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $main-color-grey;
  background-color: $main-background-Color;
}

@media screen {
  @media (min-width: $SIZE_SMALL_SCREEN) {
    html {
      min-width: 23cm;
    }
  }

  html {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @-moz-document url-prefix() {
      & {
        font-size: 16px;
      }
    }
  }

  html, body, #root{
    min-height: 100vh;
  }

  .App {
    padding: 3em 0;
    margin: auto;
  }
  .page {
    box-shadow: rgba(50, 50, 50, 0.3) 0 3px 5px;
    margin: 0 2em;
  }
}

.header-test {
  background-color: white;
}

@media print {
  .div_print {
    padding:0 ;
    margin: 0;
  }
  .notes {
    display: none;
  }
  .int {
    display: none;
  }
  .no-print {
    display: none;
  }
}

h1{
  font-size: 18px;
}

h1,h2,h3,h4,h5,h6{
  text-transform: uppercase;
}

@media (max-width: $SIZE_SMALL_SCREEN){
  .table {
    width: auto;
  }
  .table thead {
    display: none;
  }

  .table tr{
    display: block;
  }

  .table td {
    display: block;
    text-align: right;
    padding: 10px 10px 10px 10px;
    min-height: 15px;
  }

  .table tbody tr:nth-child(odd){
    border-style: none;
    background: #666;
    color: $WHITE;
  }

  .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    margin-right: 30px;
  }
}

@media (min-width: $SIZE_SMALL_SCREEN) {
  .table {
    width: 100%;
  }
  .table thead tr{
    height: 50px;
  }
  table tbody tr td{
    text-align: center;
    vertical-align: middle;
  }
  .table tbody tr {
    height: 50px;
  }
  .table tbody tr:nth-child(odd){
    border-style: none;
    background: #666;
    color: $WHITE;
  }
}
.table{
  max-width:100%;
  margin:20px auto 100px auto;
  padding:0;
  position:relative;
  border: 1px solid $GREY;
  border-collapse: collapse;
  font-family:  system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  table-layout: fixed;
  overflow: visible;
  font-size: 14px;
}

.table thead{
    background:$GREY;
}

.table tbody tr{
  border-style: none;
  background: #333;
  color: $WHITE;
}

.table tbody tr td .row-content{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 5px;
}