@import "../../Constantes.scss";

@media (min-width: $SIZE_SMALL_SCREEN) {
    .fcCard{
        color:$WHITE;
        min-height:300px;
        min-width:400px;
        margin-top: 10px;
    }

    .fcCardImg{
        width: 120px;
        height: 120px;
    }
}

@media (max-width: $SIZE_SMALL_SCREEN){
    .fcCard{
        max-width: 100%;
        max-height:100%;
        margin: 10px;
    }
    .fcCardImg{
        width: 30px;
        height: 30px;
    }
}

.fcCard{
    padding: 0;
    color:$WHITE;
    background-color:$GREY;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.fcCardContentHead{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
}

.fcCardContent{
    display: flex;
    flex-direction: row;

}

.fcCardSeparator{
    height:4px;
    width:120px;
    background-color: $SUN_FLOWER;
    margin-left: auto;
    margin-right: auto;
}

.fcCardImg{
  margin-top: 20px;
  background-color:#333;
  vertical-align: middle;
  border-radius: 50%;
  border: 3px solid $SUN_FLOWER;
}

.fcCardLabel{
    font-size: 25px;
    font-weight: 600;
    width:100%;
    margin: 10px auto 15px;
}
.fcCardDescription{
    display: block;
    min-width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight:bold;
    margin-left: auto;
    margin-right: auto;
}
.fcCardFooter{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    padding: 15px;
}