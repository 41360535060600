@import "../../Constantes";

$MARGIN-TOP:50px;
$MARGIN-BOTTOM:50px;

.showview-yellow {
  background: $main-color-back;
}

.showview{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: linear-gradient(0deg, rgba(33, 33, 33, 0.9)  0%, rgba(11, 11, 11, 0.9) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  margin:auto;
  min-height: 100vh;
  width: 40%;
}

.showview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (min-width: $SIZE_SMALL_SCREEN) {
    //min-width: 30%;
  }

  @media (max-width: $SIZE_SMALL_SCREEN) {
    //min-width: 80%;
  }

  margin-bottom: $MARGIN-BOTTOM;
  margin-top: $MARGIN-TOP;

  .divButton {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    flex: 1;
    align-items: flex-end;

    .textError {
      flex: 1;
      margin-left:25px;
      color:$ALIZARIN;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .first-view, .second-view, .thirty-view {
    width: 100%;
    height: 100%;
  }

  .first-view {
    display: block;
  }

  .second-view {
    display: none;
  }

  .thirty-view {
    display: none;
  }

  .fade-out {
    display: none;
  }

  .fade-in {
    opacity: 1;
    display: block;
  }
}

.vertical-line {
  width: 4px;
  background-color: $main-color-back;
  margin: $MARGIN-TOP 0 $MARGIN-BOTTOM 0;
  border-radius: 5px;
}

.little_dot {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: $main-color-back;
  border-radius: 50%;
  transform: translateY(-50%);
  margin-left: -4px;

  &:hover {
    width: 22px;
    height: 22px;
    margin-left: -9px;
    cursor: pointer;
  }
}

.dot {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: $main-color-back;
  border-radius: 50%;
  transform: translateY(-50%);
  margin-left: -7px;
}

.first-dot {
  top: 10% ;
}

.second-dot {
  top: 50%;
}

.thirty-dot {
  top: 90%;
}