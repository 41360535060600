
.contentStatistic {
  display: flex;
  height: 100%;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;

  .bandeauGauche {
    width: 60%;
    margin-right: 50px;
    display: flex;
    justify-content: center;
  }

  .bandeauDroit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 35%;
  }

  .stylePieChart {
    width: 50%;
    margin-bottom: 50px;
  }

  .styleStackedChart {
    width: 100%;
  }
}