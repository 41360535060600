@import "../../Constantes.scss";

body{
  padding:0;
  margin: 0;
}

.waitValidate {
  background:linear-gradient(0deg, #f1b50f 0%, $main-color-back 80%);
  min-height: 100vh;
  color: $main-color-dark;

  .div_logo {
    padding-top: 50px;
    display:flex;
    flex-direction: column;
  }
  .div_message{
    width: 400px;
    display:flex;
    flex-direction: column;
    text-align: center;
    color: white;
    border-radius: 10px;
    padding-bottom: 50px;
    padding-top: 10px;
    margin-top: 50px;
    margin-bottom: 120px;
    border: 1px solid #ffffff22;
    background: #2b2b2b linear-gradient(0deg, rgba(33, 33, 33, 0.9) 0%, rgba(11, 11, 11, 0.9) 100%);
    box-shadow: 0 7px 20px 5px #00000088;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }

  .div_message p{
    font-weight: bold;
    font-size: 12px;
  }
}





