@import "../../../../Constantes";

.imagesHelp {
  display: flex;
  justify-content: space-evenly;
}

@media (min-width: $SIZE_SMALL_SCREEN) {
  .imagesHelp{
    flex-direction: row;
  }
}

@media (max-width: $SIZE_SMALL_SCREEN){
  .imagesHelp{
    flex-direction: column;
  }
}

.imageUrssaf {
  margin-top: 15px;
  width: auto;
  height: auto;
}